import {createAction, createAsyncAction} from "typesafe-actions"

// Helpers
import {patientsByIdPayload, modalVisiblePayload} from "../helpers"
import {
    totalElementsPayload,
    totalPagesPayload,
    queryPayload,
    pagePayload
} from "../../../../store/helpers"
import {EntityByIdMap} from "../../../../models"
import {Patient, DoctorStatsInterface} from "../../models"

// Fetch list
export const fetchPatientsAsync = createAsyncAction(
    "@@patients/FETCH",
    "@@patients/FETCH_SUCCESS",
    "@@patients/FETCH_FAILURE",
)<[undefined, undefined], { patientsById: EntityByIdMap<Patient>, patientsIds: Array<Patient["id"]> }, unknown>();

// Merge
export const mergePatients = createAction("@@patients/MERGE_PATIENTS", patientsByIdPayload)();
export const safeMergePatients = createAction("@@patients/SAFE_MERGE_PATIENTS", (patientsById: EntityByIdMap<Patient>) => ({patientsById}))();

// Fetch more
export const fetchPatientsNextPageAsync = createAsyncAction(
    "@@patients/FETCH_PATIENTS_NEXT_PAGE",
    "@@patients/FETCH_PATIENTS_NEXT_PAGE_SUCCESS",
    "@@patients/FETCH_PATIENTS_NEXT_PAGE_FAILURE",
)<[undefined, undefined], { patientsById: EntityByIdMap<Patient>, patientsIds: Array<Patient["id"]> }, unknown>();

// Fetch recent
export const fetchRecentPatientsAsync = createAsyncAction(
    "@@patients/FETCH_RECENT_PATIENTS",
    "@@patients/FETCH_RECENT_PATIENTS_SUCCESS",
    "@@patients/FETCH_RECENT_PATIENTS_FAILURE",
)<[undefined, undefined], { patientsById: EntityByIdMap<Patient>, patientsIds: Array<Patient["id"]> }, unknown>();

// Pagination
export const setTotalPages = createAction("@@patients/SET_TOTAL_PAGES", totalPagesPayload)();
export const setTotalElements = createAction("@@patients/SET_TOTAL_ELEMENTS", totalElementsPayload)();
export const setCurrentPage = createAction("@@patients/SET_CURRENT_PAGE", pagePayload)();

// Search
export const setSearchQuery = createAction("@@patients/SET_SEARCH_QUERY", queryPayload)();
export const setPatientsBeingQueried = createAction("@@patients/SET_PATIENTS_BEING_QUERIED")();

// Doctor stats
export const fetchDoctorStatsAsync = createAsyncAction(
    "@@patients/FETCH_DOCTOR_STATS",
    "@@patients/FETCH_DOCTOR_STATS_SUCCESS",
    "@@patients/FETCH_DOCTOR_STATS_FAILURE",
)<[undefined, undefined], DoctorStatsInterface, unknown>();

// Invite code
export const generateInviteCodeAsync = createAsyncAction(
    "@@patients/GENERATE_INVITE_CODE",
    "@@patients/GENERATE_INVITE_CODE_SUCCEEDED",
    "@@patients/GENERATE_INVITE_CODE_FAILED",
  )<[undefined, undefined], string, unknown>()

  export const setInviteModalVisible = createAction("@@patients/SET_INVITE_MODAL_VISIBLE", modalVisiblePayload)();

