import {request} from "universal-rxjs-ajax"

// Services
import HttpService from "../../services/HttpService/HttpService"
import GroupsService from "../../modules/groups/services/GroupsService"
import AuthService from "../../modules/authorization/services/AuthService"
import PatientsService from "../../modules/patients/services/PatientsService"
import RecordsService from "../../modules/records/services/RecordsService"
import TokenStorageService from "../../services/TokenStorageService/TokenStorageService"
import Router from "../../services/Router/Router"

// Models
import GroupsServiceType from "../../modules/groups/services/GroupsServiceType"
import PatientsServiceType from "../../modules/patients/services/PatientsServiceType"
import AuthServiceType from "../../modules/authorization/services/AuthServiceType"
import RecordsServiceType from "../../modules/records/services/RecordsServiceType"
import TokenStorageServiceType from "../../services/TokenStorageService/TokenStorageServiceType"
import RouterInterface from "../../services/Router/RouterInterface"

HttpService.init(request);
TokenStorageService.getFromStorage();

export default {
    authService: AuthService,
    patientsService: PatientsService,
    groupsService: GroupsService,
    recordsService: RecordsService,
    tokenStorageService: TokenStorageService,
    router: Router
} as EpicDependenciesType

export type EpicDependenciesType = {
    authService: AuthServiceType
    patientsService: PatientsServiceType
    groupsService: GroupsServiceType
    recordsService: RecordsServiceType
    tokenStorageService: TokenStorageServiceType
    router: RouterInterface
}
