import {createAsyncAction, createAction} from "typesafe-actions"

// Helpers
import {pagePayload, totalPayload} from "../../../../store/helpers"

// Models
import {EntityByIdMap} from "../../../../models"
import {Record} from "../../models"

// Fetch list
export const fetchRecordsAsync = createAsyncAction(
    "@@records/FETCH_LIST",
    "@@records/FETCH_LIST_SUCCESS",
    "@@records/FETCH_LIST_FAILURE",
)<[undefined, undefined], { recordsById: EntityByIdMap<Record>, recordsIds: Array<Record["id"]> }, unknown>();

// Pagination
export const setTotal = createAction("@@records/SET_TOTAL", totalPayload)();
export const setPage = createAction("@@records/SET_PAGE", pagePayload)();

export const fetchMoreAsync = createAsyncAction(
    "@@records/FETCH_MORE",
    "@@records/FETCH_MORE_SUCCESS",
    "@@records/FETCH_MORE_FAILURE",
)<[undefined, undefined], { recordsById: EntityByIdMap<Record>, recordsIds: Array<Record["id"]> }, unknown>();

// Merge
export const mergeRecords = createAction("@@records/MERGE", (recordsById: EntityByIdMap<Record>) => ({recordsById}))();
