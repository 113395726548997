import {normalize} from "normalizr"

// Models
import {Patient} from "../../../patients/models"
import {RecordSummaryType} from "../../../records/models"

// Schemas
import {patient as patientSchema} from "../../../patients/store/schemas"

/**
 * @param response
 */
export const normalizeSinglePatientResponse = (response: Patient) => {
    const {entities: {groups, patients}, result} = normalize(response, patientSchema);
    const patient = patients[result];
    const groupsById = typeof groups !== "undefined" ? groups : {};
    const groupsIds = typeof groups !== "undefined" ? Object.keys(groups) : [];

    return {
        patient,
        groupsById,
        groupsIds,
    }
}

/**
 * @param id
 * @param records
 */
export const ensureRecordsHavePatientField = (id: Patient["id"], records: Array<RecordSummaryType>) => {
    return records.map(r => {
        if (typeof r.patient === "undefined") {
            if (id) {
                r.patient = {id} as Patient;
            } else {
                r.patient = null;
            }
        }

        return r
    })
};
