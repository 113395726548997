// Models
import {PatientsSearchParams} from "../models"
import PatientsServiceType from "./PatientsServiceType"
import {PageableSearchParamsType} from "../../../models"
import {CreatePatientDtoType} from "../../patients-factory/models"
import {EditPatientDtoType} from "../../patients-factory/models"

// Constants
import {PatientsEndpoint} from "../constants"
import {RequestMethod} from "../../../constants"

// Services
import AuthService from "../../authorization/services/AuthService"

const PatientsService: PatientsServiceType = Object.create(AuthService);

/**
 * @param search
 */
PatientsService.fetchPatients = function fetchPatients(search: PageableSearchParamsType & PatientsSearchParams) {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.ALL}`, {
        headers: this.getAuthHeader(),
        search,
    })
};

PatientsService.fetchRecentPatients = function fetchRecentPatients() {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.RECENT}`, {
        headers: this.getAuthHeader(),
        search: {size: 10},
    })
};

/**
 * @param dto
 */
PatientsService.createPatient = function createPatient(dto: CreatePatientDtoType) {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.CREATE}`, {
        method: RequestMethod.POST,
        body: JSON.stringify(dto),
        headers: this.getAuthHeader(),
    })
};

/**
 * @param patient
 */
PatientsService.editPatient = function editPatient(patient: EditPatientDtoType) {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.UPDATE.replace("{{patientId}}", patient.id)}`, {
        method: RequestMethod.PUT,
        body: JSON.stringify(patient),
        headers: this.getAuthHeader(),
    })
};

PatientsService.fetchDoctorStats = function fetchDoctorStats() {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.DOCTOR_STATS}`, {
        headers: this.getAuthHeader(),
    })
}

/**
 * @param id
 */
PatientsService.fetchPatientById = function fetchPatientById(id: string) {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.SINGLE.replace("{{patientId}}", id)}`, {
        headers: this.getAuthHeader(),
    })
};

PatientsService.generateCode = function () {
    return this.send(`${this.getApiUrl()}${PatientsEndpoint.GENERATE_CODE}`, {
        method: RequestMethod.POST,
        headers: this.getAuthHeader(),
    })
};

export default PatientsService
