import {of} from "rxjs"
import {Action} from "redux"
import {isActionOf} from "typesafe-actions"
import {ActionsObservable, StateObservable} from "redux-observable"
import {catchError, filter, switchMap, withLatestFrom} from "rxjs/operators"

// Models
import {AppState} from "../../../../store/models"
import {PageableResponse} from "../../../../models"
import {EpicDependenciesType} from "../../../../store/dependencies"
import PageableSearch from "../../../../models/PageableSearch/PageableSearch"

// Actions
import * as a from "../actions"
import { safeMergeGroups } from "../../../groups/store/actions"
import { safeMergePatients } from "../../../patients/store/actions"

// Selectors
import {selectSearchParams} from "../selectors"

// Helpers
import {normalizeRecordsResponse} from "../helpers"

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchRecordsEpic = (action$: ActionsObservable<Action>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRecordsAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const searchParams = selectSearchParams(state);

            return d.recordsService.fetchRecords(PageableSearch.toDto({
                ...searchParams,
                page: 0,
                sort: "time,desc",
            })).pipe(
                switchMap(({response}) => {
                    const {content, totalPages, totalElements} = response.data as PageableResponse;
                    const preparedRecords = content;
                    const {recordsById, recordsIds, patientsById, groupsById} = normalizeRecordsResponse(preparedRecords);

                    return of(
                        safeMergeGroups(groupsById),
                        safeMergePatients(patientsById),
                        a.setTotal(totalElements, totalPages),
                        a.fetchRecordsAsync.success({recordsById, recordsIds}),
                    )
                }),
                catchError(err => of(a.fetchRecordsAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchMoreRecordsEpic = (action$: ActionsObservable<Action>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchMoreAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const searchParams = selectSearchParams(state);

            return d.recordsService.fetchRecords(PageableSearch.toDto({
                ...searchParams,
                sort: "time,desc",
                page: searchParams.page - 1
            })).pipe(
                switchMap(({response}) => {
                    const {content} = response.data as PageableResponse;
                    const preparedRecords = content;
                    const {recordsById, recordsIds, patientsById, groupsById} = normalizeRecordsResponse(preparedRecords);

                    return of(
                        safeMergeGroups(groupsById),
                        safeMergePatients(patientsById),
                        a.fetchMoreAsync.success({recordsById, recordsIds}),
                    )
                }),
                catchError(err => of(a.fetchMoreAsync.failure(err))),
            )
        }),
    )
};
