import {createAsyncAction, createAction} from "typesafe-actions"
import {groupsIdPayload} from "../helpers"
import {queryPayload} from "../../../../store/helpers"

// Models
import {CreateGroupDto, Group} from "../../models"
import {EntityByIdMap} from "../../../../models"
import {Patient} from "../../../patients/models"

// Fetch list
export const fetchGroupsAsync = createAsyncAction(
    "@@groups/FETCH_GROUPS",
    "@@groups/FETCH_GROUPS_SUCCESS",
    "@@groups/FETCH_GROUPS_FAILURE",
)<[undefined, undefined], { groupsById: EntityByIdMap<Group>, groupsIds: Array<Group["id"]> }, unknown>();

// Merge groups
export const mergeGroups = createAction("@@groups/MERGE_GROUPS", groupsIdPayload)();
export const safeMergeGroups = createAction("@@groups/SAFE_MERGE_GROUPS", (groupsById: EntityByIdMap<Group>) => ({groupsById}))();

// Search
export const setGroupsSearchQuery = createAction("@@groups/SET_SEARCH_QUERY", queryPayload)();
export const setGroupsSearching = createAction("@@groups/SET_SEARCHING")();

// Fetch single
export const fetchByIdAsync = createAsyncAction(
    "@@groups/FETCH_BY_ID",
    "@@groups/FETCH_BY_ID_SUCCESS",
    "@@groups/FETCH_BY_ID_FAILURE",
)<Group["id"], Group, unknown>();

// Create group
export const createGroupAsync = createAsyncAction(
    "@@groups/CREATE_GROUP",
    "@@groups/CREATE_GROUP_SUCCESS",
    "@@groups/CREATE_GROUP_FAILURE",
)<CreateGroupDto, Group, unknown>();

// Create group
export const deleteGroupAsync = createAsyncAction(
    "@@groups/DELETE_GROUP",
    "@@groups/DELETE_GROUP_SUCCESS",
    "@@groups/DELETE_GROUP_FAILURE",
)<Group["id"], Group["id"], unknown>();

// Create group
export const addPatientToGroupAsync = createAsyncAction(
    "@@groups/ADD_PATIENT",
    "@@groups/ADD_PATIENT_SUCCESS",
    "@@groups/ADD_PATIENT_FAILURE",
)<{ groupId: Group["id"], patientId: Patient["id"] }, Group, unknown>();

// Create group
export const removePatientFromGroupAsync = createAsyncAction(
    "@@groups/REMOVE_PATIENT",
    "@@groups/REMOVE_PATIENT_SUCCESS",
    "@@groups/REMOVE_PATIENT_FAILURE",
)<{ groupId: Group["id"], patientId: Patient["id"] }, Group, unknown>();
