import {Observable} from "rxjs"
import getConfig from "next/config"
import {AjaxRequest, AjaxResponse} from "rxjs/ajax"

// Models
import {RequestOptions} from "./models"
import HttpServiceType from "./HttpServiceType"

// Helpers
import {cleanUpRequestOptions, constructRequestUrl, getRequestHeaders} from "./helpers"

const {
    publicRuntimeConfig: {API_HOST},
} = getConfig();

const HttpService: HttpServiceType = {

    /**
     * @param request
     */
    init: function (request: (options: AjaxRequest) => Observable<AjaxResponse>) {
        this.request = request;

        return this
    },

    /**
     * @param url
     * @param options
     */
    send: function (url: string, options: RequestOptions = {}): Observable<AjaxResponse> {
        if (typeof url === "undefined" || url.length === 0) {
            throw new Error("No request url provided");
        }

        const requestUrl = constructRequestUrl(url, options.search);
        const requestHeaders = getRequestHeaders(options);
        const requestOptions = cleanUpRequestOptions(options);

        process.env.NODE_ENV !== "production" && console.log("HttpRequest -> ", {
            requestUrl,
            requestHeaders,
            requestOptions,
        });

        return this.request({
            url: requestUrl,
            ...requestOptions,
            headers: requestHeaders,
        })
    },

    getApiUrl: function (): string {
        return API_HOST
    },
};

export default HttpService
