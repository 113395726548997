import {from, of} from "rxjs"
import {AnyAction} from "redux"
import {isActionOf} from "typesafe-actions"
import {ActionsObservable} from "redux-observable"
import {catchError, filter, map, switchMap} from "rxjs/operators"

// Actions
import * as a from "../actions"

/**
 * @param action$
 */
export const loadCountryCodesEpic = (action$: ActionsObservable<AnyAction>) => {
    return action$.pipe(
        filter(isActionOf(a.loadCountryCodes.request)),
        switchMap(() => {
            return from(import("../../../../../static/data/countries.json")).pipe(
                map(module => a.loadCountryCodes.success(module.default)),
                catchError(err => of(a.loadCountryCodes.failure(err))),
            )
        }),
    )
};
