// Models
import {CreateGroupDto} from "../models"
import GroupsServiceType from "./GroupsServiceType"

// Constants
import {GroupsEndpoint} from "../constants"
import {RequestMethod} from "../../../constants"

// Services
import AuthService from "../../authorization/services/AuthService"

const GroupsService: GroupsServiceType = Object.create(AuthService);

GroupsService.fetchGroups = function fetchGroups(search) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.ALL}`, {
        headers: this.getAuthHeader(),
        search,
    })
};

/**
 * @param id
 */
GroupsService.fetchSingle = function fetchSingle(id: string) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.SINGLE.replace("{{id}}", id)}`, {
        headers: this.getAuthHeader(),
    })
};

/**
 * @param dto
 */
GroupsService.createGroup = function createGroup(dto: CreateGroupDto) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.CREATE}`, {
        method: RequestMethod.POST,
        body: JSON.stringify(dto),
        headers: this.getAuthHeader(),
    })
};

/**
 * @param id
 */
GroupsService.deleteGroup = function deleteGroup(id: string) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.SINGLE.replace("{{id}}", id)}`, {
        method: RequestMethod.DELETE,
        headers: this.getAuthHeader(),
    })
};

/**
 * @param groupId
 * @param patientId
 */
GroupsService.addPatient = function addPatient(groupId: string, patientId: string) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.ADD_PATIENT.replace("{{id}}", groupId)}`, {
        method: RequestMethod.POST,
        search: {patientId},
        headers: this.getAuthHeader(),
    })
};

/**
 * @param groupId
 * @param patientId
 */
GroupsService.removePatient = function removePatient(groupId: string, patientId: string) {
    return this.send(`${this.getApiUrl()}${GroupsEndpoint.REMOVE_PATIENT.replace("{{id}}", groupId)}`, {
        method: RequestMethod.DELETE,
        search: {patientId},
        headers: this.getAuthHeader(),
    })
};

export default GroupsService
