import FormData from "form-data"

// Constants
import {AuthEndpoint} from "../constants";
import {RequestHeader, RequestMethod} from "../../../constants";

// Models
import {SignUpRequestDto, UserInterface} from "../models"
import AuthServiceType from "./AuthServiceType"

// Services
import Utils from "../../../services/Utils/Utils"
import HttpService from "../../../services/HttpService/HttpService"
import TokenStorageService from "../../../services/TokenStorageService/TokenStorageService"

const AuthService: AuthServiceType = Object.create(HttpService);

AuthService.fetchCurrentUser = function fetchCurrentUser() {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.USER}`, {
        headers: this.getAuthHeader(),
    })
};

/**
 * @param requestDto
 */
AuthService.signIn = function signIn(requestDto: FormData) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.SIGN_IN}`, {
        method: RequestMethod.POST,
        body: requestDto,
        headers: {
            [RequestHeader.AUTHORIZATION]: `basic ${Utils.base64Encode("web_app:")}`,
        },
    })
};

/**
 * @param requestDto
 */
AuthService.signUp = function signUp(requestDto: SignUpRequestDto) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.SIGN_UP}`, {
        method: RequestMethod.POST,
        body: JSON.stringify(requestDto),
    })
};

/**
 * @param token
 */
AuthService.confirmAccount = function confirmAccount(token: string) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.CONFIRM}`, {
        method: RequestMethod.POST,
        search: {token},
    })
};

/**
 * @param email
 */
AuthService.resendConfirmationEmail = function resendConfirmationEmail(email: string) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.RESEND_CONFIRMATION}`, {
        method: RequestMethod.POST,
        search: {email},
    })
};

/**
 * @param email
 */
AuthService.resetPassword = function resetPassword(email: string) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.PASSWORD_RESET}`, {
        method: RequestMethod.POST,
        search: {email},
    })
};

/**
 * @param token
 * @param password
 */
AuthService.changePassword = function changePassword(token: string, password: string) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.PASSWORD_CHANGE}`, {
        method: RequestMethod.POST,
        body: JSON.stringify({password}),
        search: {token},
    })
};

AuthService.signOut = function signOut() {
    TokenStorageService.clear();
};

AuthService.getAuthHeader = function getAuthHeader() {
    return {
        [RequestHeader.AUTHORIZATION]: `Bearer ${TokenStorageService.getToken()}`,
    }
};

AuthService.editUser = function editUser(user: UserInterface) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.EDIT_USER.replace("{{id}}", user.id)}`, {
        method: RequestMethod.PUT,
        body: JSON.stringify(user),
        headers: {
            [RequestHeader.AUTHORIZATION]: `Bearer ${TokenStorageService.getToken()}`,
        },
    })
}

AuthService.changeUserPassword = function changeUserPassword(password: string) {
    return this.send(`${this.getApiUrl()}${AuthEndpoint.PASSWORD_CHANGE}`, {
        method: RequestMethod.PATCH,
        body: JSON.stringify({password}),
        headers: {
            [RequestHeader.AUTHORIZATION]: `Bearer ${TokenStorageService.getToken()}`,
        },
    })
}

export default AuthService
