import {of, pipe} from "rxjs"
import {AnyAction} from "redux"
import {isActionOf} from "typesafe-actions"
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom
} from "rxjs/operators"
import {ActionsObservable, StateObservable} from "redux-observable"

// Actions
import * as a from "../actions"
import { mergeGroups } from "../../../groups/store/actions"

// Models
import {PatientsSearchParams, SearchAction} from "../../models"
import {AppState} from "../../../../store/models"
import {PageableResponse, PageableSearchParamsType} from "../../../../models"
import PatientsSearch from "../../models/PatientsSearch/PatientsSearch"
import PageableSearch from "../../../../models/PageableSearch/PageableSearch"

// Deps
import {EpicDependenciesType} from "../../../../store/dependencies"

// Helpers
import {normalizePatientsResponse} from "../helpers"

// Selectors
import {selectGroupsMap} from "../../../groups/store/selectors"
import {selectPatientsFilterParams, selectPatientsSearchParams} from "../selectors"

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchPatientsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchPatientsAsync.request)),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const searchParams = selectPatientsSearchParams(state);
            const filterParams = selectPatientsFilterParams(state);
            const patientsSearchDto: PageableSearchParamsType & PatientsSearchParams = Object.assign(
                Object.create(null),
                PageableSearch.toDto({...searchParams, page: 0}),
                PatientsSearch.toDto(filterParams),
            );

            return d.patientsService.fetchPatients(patientsSearchDto).pipe(
                switchMap(({response}) => {
                    const {content, totalPages, totalElements} = response.data as PageableResponse;
                    const groupsByIdFromState = selectGroupsMap(state);

                    const {
                        patientsById,
                        groupsById,
                        patientsIds,
                        groupsIds,
                    } = normalizePatientsResponse(content, groupsByIdFromState);

                    return of(
                        a.setTotalPages(totalPages),
                        a.setTotalElements(totalElements),
                        mergeGroups(groupsById, groupsIds),
                        a.fetchPatientsAsync.success({patientsById, patientsIds}),
                    )
                }),
                catchError(err => of(a.fetchPatientsAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchMorePatientsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchPatientsNextPageAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const searchParams = selectPatientsSearchParams(state);
            const filterParams = selectPatientsFilterParams(state);
            const patientsSearchDto: PageableSearchParamsType & PatientsSearchParams = Object.assign(
                Object.create(null),
                PageableSearch.toDto(searchParams),
                PatientsSearch.toDto(filterParams),
            );

            return d.patientsService.fetchPatients(patientsSearchDto).pipe(
                switchMap(({response}) => {
                    const {content} = response.data as PageableResponse;
                    const groupsIdsFromState = selectGroupsMap(state);
                    const {
                        patientsById,
                        groupsById,
                        patientsIds,
                        groupsIds,
                    } = normalizePatientsResponse(content, groupsIdsFromState);

                    return of(
                        mergeGroups(groupsById, groupsIds),
                        a.fetchPatientsNextPageAsync.success({patientsById, patientsIds}),
                    )
                }),
                catchError(err => of(a.fetchPatientsNextPageAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchRecentPatientsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRecentPatientsAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            return d.patientsService.fetchRecentPatients().pipe(
                switchMap(({response}) => {
                    const {content} = response.data as PageableResponse;
                    const groupsByIdFromState = selectGroupsMap(state);

                    const {
                        patientsById,
                        groupsById,
                        patientsIds,
                        groupsIds,
                    } = normalizePatientsResponse(content, groupsByIdFromState);

                    return of(
                        mergeGroups(groupsById, groupsIds),
                        a.fetchRecentPatientsAsync.success({patientsById, patientsIds}),
                    )
                }),
                catchError(err => of(a.fetchRecentPatientsAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 */
export const runPatientsSearchEpic = (action$: ActionsObservable<SearchAction>) => {
    return action$.pipe(
        filter(isActionOf(a.setSearchQuery)),
        pipe(
            debounceTime(500),
            distinctUntilChanged((prev, curr) => prev.payload.query === curr.payload.query),
        ),
        switchMap(() => of(a.setPatientsBeingQueried(), a.fetchPatientsAsync.request())),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchDoctorStatsEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchDoctorStatsAsync.request)),
        switchMap(() => {
            return d.patientsService.fetchDoctorStats().pipe(
                map(({response: {data}}) => {

                    return a.fetchDoctorStatsAsync.success(data)
                }),
                catchError(err => of(a.fetchDoctorStatsAsync.failure(err))),
            )
        })
    )
}

export const generateInviteCodeEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
      filter(isActionOf(a.generateInviteCodeAsync.request)),
      switchMap(() => {
        return d.patientsService.generateCode().pipe(
          map(({response}) => a.generateInviteCodeAsync.success(response.data.code)),
          catchError(error => of(a.generateInviteCodeAsync.failure(error))),
        );
      }),
    );
  }