import {combineEpics} from "redux-observable"

// Auth epics
import {
    fetchUserEpic,
    resetPasswordEpic,
    signInEpic,
    signOutEpic,
    signUpEpic,
    accountConfirmationEpic,
    changePasswordEpic,
    resendConfirmationEmailEpic,
    editUserEpic,
    changeUserPasswordEpic,
} from "../../modules/authorization/store/epics"

// Patients epics
import {
    fetchPatientsEpic,
    fetchRecentPatientsEpic,
    runPatientsSearchEpic,
    fetchMorePatientsEpic,
    fetchDoctorStatsEpic,
    generateInviteCodeEpic,
} from "../../modules/patients/store/epics"
import {createPatientEpic, editPatientEpic} from "../../modules/patients-factory/store/epics"

// Single patient
import {
    fetchMorePatientRecordsEpic,
    fetchPatientByIdEpic,
    fetchPatientRecordsEpic
} from "../../modules/single-patient/store/epics"

// Groups epics
import {
    createGroupEpic,
    fetchGroupsEpic,
    deleteGroupEpic,
    addPatientToGroupEpic,
    removePatientFromGroupEpic,
    fetchGroupByIdEpic,
    runGroupsSearchEpic,
} from "../../modules/groups/store/epics"

// Records epics
import {fetchRecordsEpic, fetchMoreRecordsEpic} from "../../modules/records/store/epics"

// Single record
import {
    markAsReviewedEpic,
    fetchRecordSummaryEpic,
    fetchRecordRawSignalEpic,
    fetchHistogramChartData,
    fetchDetails,
    fetchRecordRawFileEpic,
    fetchRecordExcelFileEpic,
    fetchRecordPdfFileEpic,
} from "../../modules/single-record/store/epics"

// Common
import {loadCountryCodesEpic} from "../../modules/common/store/epics"

export default combineEpics(
    // Auth
    fetchUserEpic,
    signInEpic,
    signOutEpic,
    signUpEpic,
    accountConfirmationEpic,
    resendConfirmationEmailEpic,
    resetPasswordEpic,
    changePasswordEpic,
    editUserEpic,
    changeUserPasswordEpic,
    // Patients
    fetchPatientsEpic,
    fetchMorePatientsEpic,
    fetchRecentPatientsEpic,
    runPatientsSearchEpic,
    createPatientEpic,
    fetchDoctorStatsEpic,
    generateInviteCodeEpic,
    editPatientEpic,
    // Single patient
    fetchPatientByIdEpic,
    fetchPatientRecordsEpic,
    fetchMorePatientRecordsEpic,
    // Groups
    fetchGroupsEpic,
    fetchGroupByIdEpic,
    createGroupEpic,
    deleteGroupEpic,
    addPatientToGroupEpic,
    removePatientFromGroupEpic,
    runGroupsSearchEpic,
    // Records
    fetchRecordsEpic,
    fetchMoreRecordsEpic,
    // Single record
    markAsReviewedEpic,
    fetchRecordSummaryEpic,
    fetchRecordRawSignalEpic,
    fetchHistogramChartData,
    fetchDetails,
    fetchRecordRawFileEpic,
    fetchRecordExcelFileEpic,
    fetchRecordPdfFileEpic,
    // Common
    loadCountryCodesEpic,
)