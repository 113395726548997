import { EMPTY, of } from "rxjs"
import { normalize } from "normalizr"
import { isActionOf } from "typesafe-actions"
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators"
import { ActionsObservable, StateObservable } from "redux-observable"

// Models
import { ChartType } from "../../../records/models"
import { AppState, IdAction } from "../../../../store/models"
import { EpicDependenciesType } from "../../../../store/dependencies"

// Constants
import { ContentType } from "../../../../constants"

// Actions
import * as a from "../actions"

// Schemas
import { RecordSummarySchema } from "../../../records/store/schemas"

// Selectors
import { selectHasRawSignals } from "../selectors"

// Helpers
import { extractAvailableLeadsFromSummary, getFilenameFromDispositionHeader } from "../../helpers"

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const markAsReviewedEpic = (action$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.markAsReviewed.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.markRecordAsReviewed(id).pipe(
                map(() => a.markAsReviewed.success(id)),
                catchError(err => of(a.markAsReviewed.failure(err.response))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchRecordSummaryEpic = (action$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchSummaryByIdAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.fetchRecordSummary(id).pipe(
                switchMap(({ response }) => {
                    const { result } = normalize(response.data, RecordSummarySchema);

                    if (typeof result.patient === "undefined") {
                        result.patient = null;
                    }

                    result.availableLeads = extractAvailableLeadsFromSummary(result);

                    return of(
                        a.setAvailableLeads(extractAvailableLeadsFromSummary(result)),
                        a.fetchSummaryByIdAsync.success(result),
                    )
                }),
                catchError(err => of(a.fetchSummaryByIdAsync.failure(err.response))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchRecordRawSignalEpic = (action$: ActionsObservable<IdAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRawSignalByIdAsync.request)),
        withLatestFrom(state$),
        switchMap(([{ payload: id }, state]) => {
            if (selectHasRawSignals(state, id)) {
                return EMPTY
            }

            return d.recordsService.fetchRawSignal(id).pipe(
                map(({ response }) => a.fetchRawSignalByIdAsync.success({ id, rawSignals: response.data })),
                catchError(err => of(a.fetchRawSignalByIdAsync.failure(err.response))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchRecordRawFileEpic = (action$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRawFileAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.downloadRawFile(id).pipe(
                map((response) => a.setRawFile(response.response, getFilenameFromDispositionHeader(response.xhr.getResponseHeader(ContentType.CONTENT_DISPOSITION)))),
                catchError(err => of(a.fetchRawFileAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchRecordExcelFileEpic = (action$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchExcelFileAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.downloadExcelFile(id).pipe(
                map((response) => a.setExcelFile(response.response, getFilenameFromDispositionHeader(response.xhr.getResponseHeader(ContentType.CONTENT_DISPOSITION)))),
                catchError(err => of(a.fetchExcelFileAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param actions$
 * @param _state$
 * @param d
 */
export const fetchRecordPdfFileEpic = (action$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchPdfFileAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.downloadPdfFile(id).pipe(
                map((response) =>  a.setPdfFile(response.response)),
                catchError(err => of(a.fetchPdfFileAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param actions$
 * @param _state$
 * @param d
 */
export const fetchHistogramChartData = (actions$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return actions$.pipe(
        filter(isActionOf(a.fetchHistogramChartDataAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.fetchChartData(id, ChartType.HISTOGRAM).pipe(
                map(({ response: { data } }) => a.fetchHistogramChartDataAsync.success({ id, data })),
                catchError(err => of(a.fetchHistogramChartDataAsync.failure(err)))
            )
        })
    )
}

/**
 * @param actions$
 * @param _state$
 * @param d
 */
export const fetchDetails = (actions$: ActionsObservable<IdAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return actions$.pipe(
        filter(isActionOf(a.fetchDetailsAsync.request)),
        switchMap(({ payload: id }) => {
            return d.recordsService.fetchRecordDetails(id).pipe(
                map(({ response: { data } }) => a.fetchDetailsAsync.success({ id, data })),
                catchError(err => of(a.fetchDetailsAsync.failure(err)))
            )
        })
    )
}
