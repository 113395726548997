import querystring, {ParsedUrlQueryInput} from "querystring"

// Models
import {RequestOptions} from "../models"

// Constants
import {ContentType, RequestHeader} from "../../../constants"

/**
 * @param {ParsedUrlQueryInput} searchParams
 */
export function getRequestQueryString(searchParams: ParsedUrlQueryInput): string {
    if (Object.keys(searchParams).length === 0) return "";

    return `?${querystring.stringify(searchParams)}`
}

/**
 * @param {string} url
 * @param {ParsedUrlQueryInput} searchParams
 */
export function constructRequestUrl(url: string, searchParams: ParsedUrlQueryInput = {}): string {
    return `${url}${getRequestQueryString(searchParams)}`
}

/**
 * @param {RequestOptions} options
 */
export function cleanUpRequestOptions(options: RequestOptions): Object {
    const {search, headers, ...rest} = options;

    return rest
}

/**
 * @param {RequestOptions} options
 */
export function getRequestHeaders(options: RequestOptions) {
    const {headers = {}} = options;

    if (typeof headers[RequestHeader.ACCEPT] === "undefined") {
        headers[RequestHeader.ACCEPT] = ContentType.JSON;
    }

    if (typeof headers[RequestHeader.CONTENT_TYPE] === "undefined" && typeof options.body === "string") {
        headers[RequestHeader.CONTENT_TYPE] = ContentType.JSON;
    }

    return headers
}

