import {normalize} from "normalizr"

// Models
import {Group} from "../../models"
import {Patient} from "../../../patients/models"
import {EntityByIdMap} from "../../../../models"

// Schemas
import {group} from "../schemas"

// Helpers
import {getNewItems} from "../../../patients/store/helpers"

/**
 * @param groupsById
 * @param groupsIds
 */
export const groupsIdPayload = (groupsById: { [key: string]: Group }, groupsIds: Array<string>) => (
    {
        groupsById,
        groupsIds,
    }
);

/**
 * @param content
 * @param patientsFromState
 */
export const normalizeGroupsResponse = (content: Array<Group>, patientsFromState: EntityByIdMap<Patient>) => {
    const {entities: {groups, patients}, result} = normalize(content, [group]);
    let newPatientsIds = [], newPatients = {};

    if (typeof patients !== "undefined") {
        newPatientsIds = getNewItems<string>(Object.keys(patients), Object.keys(patientsFromState));
        newPatients = newPatientsIds.reduce((curr, next) => ({...curr, [next]: patients[next]}), {});
    }

    return {
        groupsById: groups,
        groupsIds: result,
        patientsById: newPatients,
        patientsIds: newPatientsIds
    }
};