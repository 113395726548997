import {of} from "rxjs"
import {AnyAction} from "redux"
import {normalize} from "normalizr"
import {isActionOf} from "typesafe-actions"
import {catchError, filter, switchMap, withLatestFrom} from "rxjs/operators"
import {ActionsObservable, StateObservable} from "redux-observable"

// Models
import {AppState} from "../../../../store/models"
import {PageableResponse} from "../../../../models"
import {EpicDependenciesType} from "../../../../store/dependencies"

// Actions
import * as a from "../actions"
import { mergeGroups } from "../../../groups/store/actions"
import { mergeRecords } from "../../../records/store/actions"

// Selectors
import {selectCurrentPatientId, selectRecordsCurrentPage} from "../selectors"

// Schemas
import {RecordSchema} from "../../../records/store/schemas"

// Helpers
import {ensureRecordsHavePatientField, normalizeSinglePatientResponse} from "../helpers"

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchPatientByIdEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchByIdAsync.request)),
        switchMap(({payload: id}) => {
            return d.patientsService.fetchPatientById(id).pipe(
                switchMap(({response}) => {
                    const {patient, groupsById, groupsIds} = normalizeSinglePatientResponse(response.data);

                    return of(
                        mergeGroups(groupsById, groupsIds),
                        a.fetchByIdAsync.success(patient),
                    )
                }),
                catchError(err => of(a.fetchByIdAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchPatientRecordsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRecordsAsync.request)),
        withLatestFrom(state$),
        switchMap(([{payload: id}, state]) => {
            const page = selectRecordsCurrentPage(state);

            return d.recordsService.fetchRecordsForUser(id, {page: page - 1}).pipe(
                switchMap(({response}) => {
                    const {content, totalPages, totalElements} = response.data as PageableResponse;
                    const preparedRecords = content;
                    const {entities: {records}, result} = normalize(ensureRecordsHavePatientField(id, preparedRecords), [RecordSchema]);

                    return of(
                        mergeRecords(records),
                        a.setRecordsTotal(totalElements, totalPages),
                        a.fetchRecordsAsync.success(result),
                    )
                }),
                catchError(err => of(a.fetchRecordsAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchMorePatientRecordsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchRecordsNextPageAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const id = selectCurrentPatientId(state);
            const page = selectRecordsCurrentPage(state);

            return d.recordsService.fetchRecordsForUser(id, {page: page - 1}).pipe(
                switchMap(({response}) => {
                    const {content} = response.data as PageableResponse;
                    const preparedRecords = content;
                    const {entities: {records}, result} = normalize(preparedRecords, [RecordSchema]);

                    return of(
                        mergeRecords(records),
                        a.fetchRecordsNextPageAsync.success(result),
                    )
                }),
                catchError(err => of(a.fetchRecordsNextPageAsync.failure(err))),
            )
        }),
    )
};