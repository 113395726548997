const BASE_PATH = "record";
const SINGLE_RECORD_BASE_PATH = "record/{{id}}";

export const INITIAL_RECORDS_PAGE = 1;
export const RecordsEndpoint = {
    ALL: `${BASE_PATH}/summary`,
    SINGLE: SINGLE_RECORD_BASE_PATH,
    SINGLE_SUMMARY: `${SINGLE_RECORD_BASE_PATH}/summary`,
    SINGLE_DETAILS: `${SINGLE_RECORD_BASE_PATH}/details`,
    SINGLE_RAW_SIGNAL: `${SINGLE_RECORD_BASE_PATH}/rawSignal`,
    DOWNLOAD_RAW_FILE: `${SINGLE_RECORD_BASE_PATH}/rawFile`,
    DOWNLOAD_EXCEL_FILE: `${SINGLE_RECORD_BASE_PATH}/report/excel`,
    DOWNLOAD_PDF_FILE: `${SINGLE_RECORD_BASE_PATH}/short/report/pdf`,
    SINGLE_REVIEW: `${SINGLE_RECORD_BASE_PATH}/reviewed`,
    ALL_FOR_USER: `${BASE_PATH}/user/{{userId}}/record`,
    CHART: `${SINGLE_RECORD_BASE_PATH}/chart`,
};