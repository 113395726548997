import {normalize} from "normalizr"

// Models
import {Record} from "../../models"
import {EntityByIdMap} from "../../../../models"
import {Patient} from "../../../patients/models"

// Schemas
import {RecordSchema} from "../schemas"

/**
 * @param recordsById
 * @param recordsIds
 */
export const recordsByIdPayload = (recordsById: EntityByIdMap<Record>, recordsIds: Array<Record["id"]>) => ({
    payload: {
        recordsById,
        recordsIds,
    },
});

export const normalizeRecordsResponse = (recordsList: Array<Record & { patient: Patient }>) => {
    const {entities: {records, patients, groups}, result} = normalize(recordsList, [RecordSchema]);

    return {
        recordsById: records,
        recordsIds: result,
        patientsById: patients || {},
        groupsById: groups || {},
    }
};