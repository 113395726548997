import {schema} from "normalizr"
import {patient} from "../../../patients/store/schemas"

const RecordSchema = new schema.Entity("records", {
    patient,
});
const RecordSummarySchema = new schema.Object({patient});

export {
    RecordSchema,
    RecordSummarySchema,
}
