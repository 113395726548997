import {of} from "rxjs"
import {isActionOf} from "typesafe-actions"
import {catchError, filter, switchMap} from "rxjs/operators"
import {ActionsObservable, StateObservable} from "redux-observable"

// Models
import {CreatePatientAction} from "../../models"
import {EditPatientAction} from "../../models"
import {AppState} from "../../../../store/models"
import {EpicDependenciesType} from "../../../../store/dependencies"

// Actions
import * as a from "../actions"
import { fetchPatientsAsync } from "../../../patients/store/actions"

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const createPatientEpic = (action$: ActionsObservable<CreatePatientAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.createPatientAsync.request)),
        switchMap(({payload: patient}) => {
            return d.patientsService.createPatient(patient).pipe(
                switchMap(({response}) => of(
                    fetchPatientsAsync.request(),
                    a.createPatientAsync.success(response.data)),
                ),
                catchError(err => {
                    if (typeof err === "undefined") {
                        return of(a.createPatientAsync.failure("Unknown error occurred"))
                    }

                    const {response} = err;

                    if (!response) {
                        return of(a.createPatientAsync.failure("Unknown error occurred"))
                    }

                    if (typeof response.message !== "undefined") {
                        return of(a.createPatientAsync.failure(response.message))
                    }

                    return of(a.createPatientAsync.failure("Unknown error occurred"))
                }),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const editPatientEpic = (action$: ActionsObservable<EditPatientAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.editPatientAsync.request)),
        switchMap(({payload: patient}) => {
            return d.patientsService.editPatient(patient).pipe(
                switchMap(({response}) => of(
                    a.editPatientAsync.success({...response.data, groups: response.data.groups.map(g => g.id)})),
                ),
                catchError(err => {
                    if (typeof err === "undefined") {
                        return of(a.editPatientAsync.failure("Unknown error occurred"))
                    }

                    const {response} = err;

                    if (!response) {
                        return of(a.createPatientAsync.failure("Unknown error occurred"))
                    }

                    if (typeof response.message !== "undefined") {
                        return of(a.editPatientAsync.failure(response.message))
                    }

                    return of(a.editPatientAsync.failure("Unknown error occurred"))
                }),
            )
        }),
    )
};