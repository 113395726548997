import {normalize} from "normalizr"

// Models
import {Patient} from "../../models"
import {EntityByIdMap} from "../../../../models"
import {Group} from "../../../groups/models"

// Schemas
import {patient} from "../schemas"

/**
 * @param patientsById
 * @param patientsIds
 */
export const patientsByIdPayload = (patientsById: EntityByIdMap<Patient>, patientsIds: Array<Patient["id"]>) => (
    {
        patientsById,
        patientsIds,
    }
);

/**
 * @param newItems
 * @param existingItems
 */
export const getNewItems = <T>(newItems: Array<T>, existingItems: Array<T>): Array<T> => {
    return newItems.filter(e => existingItems.indexOf(e) === -1)
};

/**
 * @param content
 * @param groupsFromState
 */
export const normalizePatientsResponse = (content: Array<Patient>, groupsFromState: EntityByIdMap<Group>) => {
    const {entities: {patients, groups}, result} = normalize(content, [patient]);
    let newGroupsIds = [], newGroups = {};
    let patientsById = {};

    if (typeof groups !== "undefined") {
        newGroupsIds = getNewItems<string>(Object.keys(groups), Object.keys(groupsFromState));
        newGroups = newGroupsIds.reduce((curr, next) => ({...curr, [next]: groups[next]}), {});
    }

    if (typeof patients !== "undefined") {
        patientsById = patients;
    }

    return {
        patientsById,
        patientsIds: result,
        groupsById: newGroups,
        groupsIds: newGroupsIds,
    }
};

export const modalVisiblePayload = (visible: boolean) => ({visible});
