import {from, of} from "rxjs"
import {AnyAction} from "redux"
import FormData from "form-data"
import {isActionOf} from "typesafe-actions"
import {ActionsObservable, StateObservable} from "redux-observable"
import {catchError, filter, map, mergeMap, switchMap} from "rxjs/operators"

// Actions
import * as a from "../actions"

// Models
import {AppState} from "../../../../store/models"
import {EpicDependenciesType} from "../../../../store/dependencies"
import {
    AccountConfirmationAction,
    ChangePasswordAction,
    ResendConfirmationAction,
    ResetPasswordAction,
    SignInAction,
    SignInResponseInterface,
    SignUpAction,
    UserInterface,
    UserResponseInterface
} from "../../models"

// Constants
import {Role, Routes} from "../../../../constants"
import {AuthRequestProperty} from "../../constants"

// Utils
import Utils from "../../../../services/Utils/Utils"

/**
 * @param {ActionsObservable} action$
 * @param {StateObservable} _state$
 * @param {EpicDependenciesType} d
 */
export const fetchUserEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchCurrentUser)),
        mergeMap(() => {
            return d.authService.fetchCurrentUser().pipe(
                map(response => {
                    const {data}: UserResponseInterface = response.response;
                    console.log(data);
                    return a.fetchCurrentUserSuccess(data)
                }),
                catchError(err => of(a.fetchCurrentUserFailure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const signInEpic = (action$: ActionsObservable<SignInAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf([a.signIn, a.demoSignIn])),
        switchMap(({payload: {credentials: {username, password}}}) => {
            const requestDto = new FormData();

            requestDto.append(AuthRequestProperty.USERNAME, username);
            requestDto.append(AuthRequestProperty.PASSWORD, password);
            requestDto.append(AuthRequestProperty.GRANT_TYPE, AuthRequestProperty.PASSWORD);

            return d.authService.signIn(requestDto).pipe(
                map(response => {
                    const {access_token, expires_in, details}: SignInResponseInterface = response.response;
                       
                    if (details.role === Role.PATIENT || details.role === Role.DEMO_PATIENT || details.role === Role.COMPANY_PATIENT) {
                        return a.signInFailure("Please, use doctor account")
                    }

                    d.tokenStorageService.saveToStorage(access_token, expires_in);

                    void d.router.redirect(Routes.PATIENTS);

                    return a.signInSuccess(details)
                }),
                catchError(err => of(a.signInFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};

/**
 * @param actions$
 * @param _state$
 * @param d
 */
export const editUserEpic = (actions$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return actions$.pipe(
        filter(isActionOf(a.editUser.request)),
        switchMap(({payload: user}) => {
            return d.authService.editUser(user).pipe(
                map(({response}) => a.editUser.success(response as UserInterface)),
                catchError(err => of(a.editUser.failure(err)))
            )
        })
    )
}

/**
 * @param actions$
 * @param _state$
 * @param d
 */
export const changeUserPasswordEpic = (actions$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return actions$.pipe(
        filter(isActionOf(a.changeUserPassword.request)),
        switchMap(({payload: password}) => {
            return d.authService.changeUserPassword(password).pipe(
                map(() => a.changeUserPassword.success()),
                catchError(err => of(a.changeUserPassword.failure(err)))
            )
        })
    )
}

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const signOutEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.signOut)),
        switchMap(() => {
            d.authService.signOut();

            return from(d.router.redirect(Routes.SIGN_IN)).pipe(
                map(() => a.signOutSuccess()),
                catchError(err => of(a.signOutFailure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const signUpEpic = (action$: ActionsObservable<SignUpAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.signUp)),
        switchMap(({payload: {requestDto}}) => {
            return d.authService.signUp(requestDto).pipe(
                map(() => a.signUpSuccess()),
                catchError(err => of(a.signUpFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const accountConfirmationEpic = (action$: ActionsObservable<AccountConfirmationAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.confirmAccount)),
        switchMap(({payload: {token}}) => {
            return d.authService.confirmAccount(token).pipe(
                map(() => a.confirmAccountSuccess()),
                catchError(err => of(a.confirmAccountFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const resendConfirmationEmailEpic = (action$: ActionsObservable<ResendConfirmationAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.resendConfirmation)),
        switchMap(({payload: {email}}) => {
            return d.authService.resendConfirmationEmail(email).pipe(
                map(() => a.resendConfirmationSuccess()),
                catchError(err => of(a.resendConfirmationFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const resetPasswordEpic = (action$: ActionsObservable<ResetPasswordAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.resetPassword)),
        switchMap(({payload: {email}}) => {
            return d.authService.resetPassword(email).pipe(
                map(() => a.resetPasswordSuccess()),
                catchError(err => of(a.resetPasswordFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param {EpicDependenciesType} d
 */
export const changePasswordEpic = (action$: ActionsObservable<ChangePasswordAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.changePassword)),
        switchMap(({payload: {token, password}}) => {
            return d.authService.changePassword(token, password).pipe(
                map(() => a.changePasswordSuccess()),
                catchError(err => of(a.changePasswordFailure(Utils.getServerErrorMessage(err)))),
            )
        }),
    )
};