import {PageableSearchParamsType, PageableSearchType} from "../index"

const PageableSearch: PageableSearchType = Object.create(null);

PageableSearch.toDto = function toDto(searchParams: PageableSearchParamsType) {
    const {page, query, size, sort} = searchParams;

    const params: PageableSearchParamsType = {
        page,
    };

    if (!!size) {
        params.size = size;
    }

    if (!!sort) {
        params.sort = sort;
    }

    if (!!query) {
        params.query = query;
    }

    return params
};

export default PageableSearch
