import {GroupsSearchParams} from "../index"

const SearchParams = Object.create(null);

SearchParams.toDto = function toDto(params: GroupsSearchParams) {
    const search = {} as GroupsSearchParams;
    const {name} = params;

    if (!!name) {
        search.name = name;
    }

    return search
};

export default SearchParams
