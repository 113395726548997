import {schema} from "normalizr"

// Schemas
import {patient} from "../../../patients/store/schemas"

const group = new schema.Entity("groups", {
    patients: [patient],
});

export {
    group,
}
