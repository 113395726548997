import {createAsyncAction, createAction} from "typesafe-actions"

// Models
import {CreatePatientDtoType} from "../../models"
import {UserInterface} from "../../../authorization/models"
import {EditPatientDtoType} from "../../models"

// Helpers
import {activeIdentifierPayload, activeSexPayload, modalVisiblePayload} from "../helpers"

// Create patient
export const createPatientAsync = createAsyncAction(
    "@@patients-factory/CREATE_REQUEST",
    "@@patients-factory/CREATE_SUCCESS",
    "@@patients-factory/CREATE_FAILURE",
)<CreatePatientDtoType, UserInterface, string>();

// Edit patient
export const editPatientAsync = createAsyncAction(
    "@@patients-factory/EDIT_REQUEST",
    "@@patients-factory/EDIT_SUCCESS",
    "@@patients-factory/EDIT_FAILURE",
)<EditPatientDtoType, UserInterface, string>();

// Tabs
export const selectSex = createAction("@@patients-factory/SELECT_SEX", activeSexPayload)();
export const selectIdentifier = createAction("@@patients-factory/SELECT_IDENTIFIER", activeIdentifierPayload)();

// Modal
export const setCreateModalVisible = createAction("@@patients-factory/SET_CREATE_MODAL_VISIBLE", modalVisiblePayload)();
export const setEditModalVisible = createAction("@@patients-factory/SET_EDIT_MODAL_VISIBLE", modalVisiblePayload)();

// Country code
export const selectCountryCode = createAction("@@patients-factory/SELECT_COUNTRY_CODE", (code: string) => ({code}))();
