import AuthService from "../../authorization/services/AuthService"

// Constants
import { RecordsEndpoint } from "../constants"

// Models
import RecordsServiceType from "./RecordsServiceType"
import { PageableSearchParamsType } from "../../../models"
import { ChartType } from "../models"
import TokenStorageService from "../../../services/TokenStorageService/TokenStorageService"

// Constants
import { RequestMethod } from "../../../constants"

const RecordsService: RecordsServiceType = Object.create(AuthService);

RecordsService.fetchRecords = function fetchRecords(search: PageableSearchParamsType) {
    return this.send(`${this.getApiUrl()}${RecordsEndpoint.ALL}`, {
        search,
        headers: this.getAuthHeader()
    })
};

RecordsService.fetchRecordsForUser = function fetchRecordsForUser(id: string, search: PageableSearchParamsType) {
    return this.send(`${this.getApiUrl()}${RecordsEndpoint.ALL_FOR_USER.replace("{{userId}}", id)}`, {
        search,
        headers: this.getAuthHeader(),
    })
};

RecordsService.fetchRecordSummary = function fetchRecordSummary(id: string) {
    let authentication = 0;
    if (TokenStorageService.getToken()) {
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_SUMMARY.replace("{{id}}", id)}`, {
            headers: this.getAuthHeader(),
            search: {
                authentication
            },
        })
    } else {
        authentication = 1;
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_SUMMARY.replace("{{id}}", id)}`, {
            search: {
                authentication
            },
        })
    }
};

RecordsService.fetchRawSignal = function fetchRawSignal(id: string) {
    let authentication = 0;
    if (TokenStorageService.getToken()) {
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_RAW_SIGNAL.replace("{{id}}", id)}`, {
            headers: this.getAuthHeader(),
            search: {
                authentication
            },
        })
    } else {
        authentication = 1;
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_RAW_SIGNAL.replace("{{id}}", id)}`, {
            search: {
                authentication
            },
        })
    }
};

RecordsService.downloadRawFile = function downloadRawFile(id: string) {
    return this.send(`${this.getApiUrl()}${RecordsEndpoint.DOWNLOAD_RAW_FILE.replace("{{id}}", id)}`, {
        headers: this.getAuthHeader(),
        responseType: "blob",
    })
};

RecordsService.downloadExcelFile = function downloadExcelFile(id: string) {
    return this.send(`${this.getApiUrl()}${RecordsEndpoint.DOWNLOAD_EXCEL_FILE.replace("{{id}}", id)}`, {
        headers: this.getAuthHeader(),
        responseType: "blob",
    })
};

RecordsService.downloadPdfFile = function downloadPdfFile(id: string) {

    var Lang =  localStorage.getItem("Lng");

    return this.send(`${this.getApiUrl()}${RecordsEndpoint.DOWNLOAD_PDF_FILE.replace("{{id}}", id)}`, {

        headers: {"Authorization":`Bearer ${TokenStorageService.getToken()}`,"Accept-Language":Lang},

        responseType: "blob",
    })
};

RecordsService.markRecordAsReviewed = function markRecordAsReviewed(id: string) {
    return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_REVIEW.replace("{{id}}", id)}`, {
        method: RequestMethod.PUT,
        headers: this.getAuthHeader(),
    })
};

RecordsService.fetchChartData = function fetchChartData(id: string, chartType: ChartType) {
    let authentication = 0;
    if (TokenStorageService.getToken()) {
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.CHART.replace("{{id}}", id)}`, {
            headers: this.getAuthHeader(),
            search: {
                chartType,
                authentication
            },
        });
    } else {
        authentication = 1;
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.CHART.replace("{{id}}", id)}`, {
            search: {
                chartType,
                authentication
            },
        });
    }
}

RecordsService.fetchRecordDetails = function fetchRecordDetails(id: string) {
    let authentication = 0;
    if (TokenStorageService.getToken()) {
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_DETAILS.replace("{{id}}", id)}`, {
            headers: this.getAuthHeader(),
            search: {
                authentication
            },
        });
    } else {
        authentication = 1;
        return this.send(`${this.getApiUrl()}${RecordsEndpoint.SINGLE_DETAILS.replace("{{id}}", id)}`, {
            search: {
                authentication
            },
        });
    }
}

export default RecordsService
