import {PatientsSearchParams, PatientsSearchType} from "../index"
import {FILTER_DEFAULTS} from "../../constants"

const PatientsSearch: PatientsSearchType = Object.create(null);

/**
 * @param patientsSearch
 */
PatientsSearch.toDto = function toDto(patientsSearch: PatientsSearchParams) {
    const search: PatientsSearchParams = {};
    const {sex, ageFrom, ageTo, groupIds} = patientsSearch;

    if (!!ageFrom) {
        search.ageFrom = ageFrom;
    }

    if (!!ageTo && ageTo < FILTER_DEFAULTS.ageTo) {
        search.ageTo = ageTo;
    }

    if (!!sex && sex !== FILTER_DEFAULTS.sex) {
        search.sex = sex;
    }

    if (!!groupIds) {
        search.groupIds = groupIds;
    }

    return search
};

export default PatientsSearch
