import {of, pipe} from "rxjs"
import {AnyAction} from "redux"
import {isActionOf} from "typesafe-actions"
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, withLatestFrom} from "rxjs/operators"
import {ActionsObservable, StateObservable} from "redux-observable"

// Actions
import * as a from "../actions"
import { mergePatients } from "../../../patients/store/actions"

// Models
import {CreateGroupAction} from "../../models"
import {AppState} from "../../../../store/models"
import {EpicDependenciesType} from "../../../../store/dependencies"
import {SearchAction} from "../../../patients/models"

// Helpers
import {normalizeGroupsResponse} from "../helpers"
import SearchParams from "../../models/SearchParams/SearchParams"

// Selectors
import {selectPatientsMap} from "../../../patients/store/selectors"
import {selectGroupsSearchQuery} from "../selectors"

/**
 * @param action$
 * @param state$
 * @param d
 */
export const fetchGroupsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchGroupsAsync.request)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const searchQuery = selectGroupsSearchQuery(state)
            const dto = SearchParams.toDto({name: searchQuery});

            return d.groupsService.fetchGroups(dto).pipe(
                switchMap(({response}) => {
                    const patientsByIdFromState = selectPatientsMap(state)
                    const {
                        groupsById,
                        groupsIds,
                        patientsById,
                        patientsIds,
                    } = normalizeGroupsResponse(response.data, patientsByIdFromState)

                    return of(
                        mergePatients(patientsById, patientsIds),
                        a.fetchGroupsAsync.success({groupsById, groupsIds}),
                    )
                }),
                catchError(err => of(a.fetchGroupsAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 */
export const runGroupsSearchEpic = (action$: ActionsObservable<SearchAction>) => {
    return action$.pipe(
        filter(isActionOf(a.setGroupsSearchQuery)),
        pipe(
            debounceTime(500),
            distinctUntilChanged((prev, curr) => prev.payload.query === curr.payload.query),
        ),
        switchMap(() => of(a.setGroupsSearching(), a.fetchGroupsAsync.request())),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const fetchGroupByIdEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.fetchByIdAsync.request)),
        switchMap(({payload: id}) => {
            return d.groupsService.fetchSingle(id).pipe(
                map(({response}) => a.fetchByIdAsync.success(response.data)),
                catchError(err => of(a.fetchByIdAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const createGroupEpic = (action$: ActionsObservable<CreateGroupAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.createGroupAsync.request)),
        switchMap(({payload: group}) => {
            return d.groupsService.createGroup(group).pipe(
                map(({response}) => a.createGroupAsync.success(response.data)),
                catchError(err => of(a.createGroupAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const deleteGroupEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.deleteGroupAsync.request)),
        switchMap(({payload: id}) => {
            return d.groupsService.deleteGroup(id).pipe(
                map(() => a.deleteGroupAsync.success(id)),
                catchError(err => of(a.deleteGroupAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const addPatientToGroupEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.addPatientToGroupAsync.request)),
        switchMap(({payload: {groupId, patientId}}) => {
            return d.groupsService.addPatient(groupId, patientId).pipe(
                map(({response}) => a.addPatientToGroupAsync.success(response.data)),
                catchError(err => of(a.addPatientToGroupAsync.failure(err))),
            )
        }),
    )
};

/**
 * @param action$
 * @param _state$
 * @param d
 */
export const removePatientFromGroupEpic = (action$: ActionsObservable<AnyAction>, _state$: StateObservable<AppState>, d: EpicDependenciesType) => {
    return action$.pipe(
        filter(isActionOf(a.removePatientFromGroupAsync.request)),
        switchMap(({payload: {groupId, patientId}}) => {
            return d.groupsService.removePatient(groupId, patientId).pipe(
                map(({response}) => a.removePatientFromGroupAsync.success(response.data)),
                catchError(err => of(a.removePatientFromGroupAsync.failure(err))),
            )
        }),
    )
};
