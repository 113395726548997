import {schema} from "normalizr"

const patientGroup = new schema.Entity("groups");
const patient = new schema.Entity("patients", {
    groups: [patientGroup],
});

export {
    patientGroup,
    patient,
}
